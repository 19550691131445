import { css, cx } from "@emotion/css";
import { borderRadius, space, themeTokens, text } from "@octopusdeploy/design-system-tokens";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { resetStyles } from "../../utils/resetStyles";
import type { ButtonImportance, ButtonSize } from "./Button";
export function getButtonStyles(importance: ButtonImportance, disabled: boolean, size: ButtonSize) {
    const buttonVariantStyles = getButtonVariantStyles(importance, disabled, size);
    return cx([baseButtonStyles, buttonVariantStyles]);
}
const baseButtonStyles = css({
    ...resetStyles.button,
    font: text.regular.bold.large,
    WebkitFontSmoothing: "antialiased",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, border-color, outline, color, fill",
    transitionDuration: "0.2s",
    borderRadius: borderRadius.small,
    paddingInlineStart: space[12],
    paddingInlineEnd: space[12],
    whiteSpace: "nowrap",
});
function getButtonVariantStyles(importance: ButtonImportance, disabled: boolean, size: ButtonSize): string {
    const importanceLookup = getImportanceLookupValue(importance, disabled);
    if (importanceLookup === "disabled") {
        return css({
            color: themeTokens.color.button.text.disabled,
            background: themeTokens.color.button.background.disabled,
            font: size === "medium" ? text.regular.bold.medium : text.regular.bold.small,
            border: `solid ${buttonBorderWidth} ${themeTokens.color.button.border.disabled}`,
            padding: getButtonPadding(size, true),
            cursor: "not-allowed",
            svg: {
                fill: themeTokens.color.button.icon.disabled,
            },
        });
    }
    const backgroundTokens = themeTokens.color.button.background[importanceLookup];
    const borderColor = getBorderTokens(importanceLookup);
    const textColor = themeTokens.color.button.text[importanceLookup];
    const iconColor = themeTokens.color.button.icon[importanceLookup];
    const padding = getButtonPadding(size, borderColor !== undefined);
    return css({
        color: textColor,
        background: backgroundTokens.default,
        border: borderColor ? `solid ${buttonBorderWidth} ${borderColor}` : undefined,
        font: size === "medium" ? text.regular.bold.medium : text.regular.bold.small,
        // we need to subtract the border width for the button to be the correct height (40px, not 42px)
        padding,
        svg: {
            fill: iconColor,
        },
        ":hover": {
            background: backgroundTokens.hover,
        },
        ":focus-visible": {
            background: backgroundTokens.hover,
        },
        ":active": {
            background: backgroundTokens.pressed,
        },
    });
}
function getButtonPadding(size: ButtonSize, hasBorder: boolean) {
    const basePaddingSize = size === "medium" ? space[8] : space[6];
    const padding = `calc(${basePaddingSize} - ${hasBorder ? buttonBorderWidth : "0px"})`;
    return padding;
}
function getBorderTokens(importance: TokenButtonImportance) {
    switch (importance) {
        case "primary":
            return undefined;
        case "secondary":
            return themeTokens.color.button.border.secondary;
        case "tertiary":
            return undefined;
        case "destructive":
            return undefined;
        case "quiet":
            return undefined;
        case "loud":
            return undefined;
        case "neutral":
            return undefined;
        case "disabled":
            return themeTokens.color.button.border.disabled;
        default:
            exhaustiveCheck(importance, `Could not find lookup value for unknown variant: ${importance}`);
    }
}
function getImportanceLookupValue(importance: ButtonImportance, disabled: boolean): TokenButtonImportance {
    // Our tokens include the "disabled" as a type of button, at the same level as "primary", "secondary" etc
    // This differs to the Button component API where disabled is a prop separate to the importance..
    // Here we map the disabled prop from the component API into the token equivalent.
    if (disabled) {
        return "disabled";
    }
    switch (importance) {
        case "primary":
            return "primary";
        case "secondary":
            return "secondary";
        case "tertiary":
            return "tertiary";
        case "destructive":
            return "destructive";
        case "quiet":
            return "quiet";
        case "loud":
            return "loud";
        case "neutral":
            return "neutral";
        default:
            exhaustiveCheck(importance, `Could not find lookup value for unknown variant: ${importance}`);
    }
}
export const labelStyles = css({
    marginRight: space[4],
    marginLeft: space[4],
});
type TokenButtonImportance = keyof typeof themeTokens.color.button.background;
const buttonBorderWidth = "1px";
