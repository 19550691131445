import { css, cx } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { ExperimentIcon, TriangleExclamationIcon } from "../Icon";
import { CircleCheckIcon } from "../Icon/CircleCheckIcon";
import { CircleXMarkIcon } from "../Icon/CircleXMarkIcon";
import { HandWaveIcon } from "../Icon/HandWaveIcon";
import { SparklesIcon } from "../Icon/SparklesIcon";
import { IconButton } from "../IconButton";
export const calloutTypes = ["information", "success", "warning", "danger", "new-feature", "generic"] as const;
export type CalloutType = (typeof calloutTypes)[number];
interface OptionalTitleProps {
    title?: React.ReactNode;
    hideTitle: true;
}
interface RequiredTitleProps {
    title: React.ReactNode;
    hideTitle?: false;
}
type TitleProps = OptionalTitleProps | RequiredTitleProps;
type CalloutTypeProps = {
    type: "new-feature";
    isExperimentalFeature?: boolean;
} | {
    type: CalloutType;
    isExperimentalFeature?: never;
};
type CalloutProps = TitleProps & CalloutTypeProps & {
    canClose?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
};
export const Callout = (props: CalloutProps) => {
    let iconElement = null;
    const containerStyles = stylesByType[props.type].container;
    const iconContainerStyles = stylesByType[props.type].iconContainer;
    const titleStyles = stylesByType[props.type].title;
    switch (props.type) {
        case "information":
            iconElement = <HandWaveIcon />;
            break;
        case "success":
            iconElement = <CircleCheckIcon size={24}/>;
            break;
        case "warning":
            iconElement = <TriangleExclamationIcon size={24}/>;
            break;
        case "danger":
            iconElement = <CircleXMarkIcon size={24}/>;
            break;
        case "new-feature":
            iconElement = props.isExperimentalFeature ? <ExperimentIcon /> : <SparklesIcon />;
            break;
        case "generic":
            break;
    }
    return (<div className={containerStyles} role="alert">
            {iconElement && <div className={iconContainerStyles}>{iconElement}</div>}
            <div className={contentContainerStyles}>
                {!props.hideTitle && <div className={titleStyles}>{props.title}</div>}
                {props.children && <div className={bodyStyles}>{props.children}</div>}
            </div>
            {props.canClose && (<div>
                    <IconButton className={closeButtonStyles} accessibleName="Close Button" onClick={props.onClose} icon="Cancel"/>
                </div>)}
        </div>);
};
Callout.displayName = "Callout";
const baseContainerStyles = css({
    display: "flex",
    padding: `${space[8]} ${space[12]}`,
    gap: space[8],
});
// This creates a background with a solid border on the left edge
function buildBackgroundGradient(borderColor: string, backgroundColor: string) {
    return `linear-gradient(90deg, ${borderColor} 0%, ${borderColor} ${space[4]}, ${backgroundColor} ${space[4]}, ${backgroundColor} 100%)`;
}
const baseIconContainerStyles = css({
    gridArea: "icon",
    height: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
const baseTitleStyles = css({
    font: text.regular.bold.medium,
});
const stylesByType: Record<CalloutType, Record<"container" | "iconContainer" | "title", string>> = {
    information: {
        container: cx(baseContainerStyles, css({
            background: buildBackgroundGradient(themeTokens.color.callout.border.info, themeTokens.color.callout.background.info.default),
        })),
        iconContainer: cx(baseIconContainerStyles, css({
            color: themeTokens.color.callout.icon.info,
        })),
        title: cx(baseTitleStyles, css({
            color: themeTokens.color.callout.text.info,
        })),
    },
    success: {
        container: cx(baseContainerStyles, css({
            background: buildBackgroundGradient(themeTokens.color.callout.border.success, themeTokens.color.callout.background.success.default),
        })),
        iconContainer: cx(baseIconContainerStyles, css({
            color: themeTokens.color.callout.icon.success,
        })),
        title: cx(baseTitleStyles, css({
            color: themeTokens.color.callout.text.success,
        })),
    },
    warning: {
        container: cx(baseContainerStyles, css({
            background: buildBackgroundGradient(themeTokens.color.callout.border.warning, themeTokens.color.callout.background.attention.default),
        })),
        iconContainer: cx(baseIconContainerStyles, css({
            color: themeTokens.color.callout.icon.warning,
        })),
        title: cx(baseTitleStyles, css({
            color: themeTokens.color.callout.text.warning,
        })),
    },
    danger: {
        container: cx(baseContainerStyles, css({
            background: buildBackgroundGradient(themeTokens.color.callout.border.danger, themeTokens.color.callout.background.danger.default),
        })),
        iconContainer: cx(baseIconContainerStyles, css({
            color: themeTokens.color.callout.icon.danger,
        })),
        title: cx(baseTitleStyles, css({
            color: themeTokens.color.callout.text.danger,
        })),
    },
    "new-feature": {
        container: cx(baseContainerStyles, css({
            background: buildBackgroundGradient(themeTokens.color.callout.border.feature, themeTokens.color.callout.background.feature.default),
        })),
        iconContainer: cx(baseIconContainerStyles, css({
            color: themeTokens.color.callout.icon.feature,
        })),
        title: cx(baseTitleStyles, css({
            color: themeTokens.color.callout.text.feature,
        })),
    },
    generic: {
        container: cx(baseContainerStyles, css({
            background: buildBackgroundGradient(themeTokens.color.callout.border.info, themeTokens.color.callout.background.info.default),
        })),
        iconContainer: cx(baseIconContainerStyles, css({
            color: themeTokens.color.callout.icon.info,
        })),
        title: cx(baseTitleStyles, css({
            color: themeTokens.color.callout.text.info,
        })),
    },
};
const contentContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[4],
    flex: 1,
});
const bodyStyles = css({
    font: text.regular.default.medium,
    color: themeTokens.color.callout.text.body,
});
const closeButtonStyles = css({
    // We don't have a great way of customizing colors for components yet, so this is a hacky workaround
    "&.MuiIconButton-root": {
        color: themeTokens.color.icon.secondary,
    },
});
