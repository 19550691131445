import { css, cx } from "@emotion/css";
import { space, themeTokens, borderRadius, text } from "@octopusdeploy/design-system-tokens";
import { resetStyles } from "../../utils/resetStyles";
const navigationBarItemStyles = css({
    font: text.regular.default.medium,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "2.25rem",
    minWidth: "2.25rem",
    borderRadius: borderRadius.small,
    background: "transparent",
    ":focus-visible": {
        background: themeTokens.color.navigation.link.background.hover,
        boxShadow: themeTokens.shadow.focused,
        zIndex: 1,
    },
    ":hover": {
        color: themeTokens.color.navigation.link.icon.hover,
        background: themeTokens.color.navigation.link.background.hover,
    },
    ":active": {
        color: themeTokens.color.navigation.link.icon.pressed,
        background: themeTokens.color.navigation.link.background.pressed,
    },
});
const navigationBarBaseLinkStyles = cx(css(resetStyles.anchor, {
    "&[aria-current=page]": {
        background: themeTokens.color.navigation.link.background.active,
    },
}), navigationBarItemStyles);
export const navigationBarTextLinkStyles = cx(navigationBarBaseLinkStyles, css({
    paddingInlineStart: space[12],
    paddingInlineEnd: space[12],
}));
export const navigationBarIconLinkStyles = navigationBarBaseLinkStyles;
const navigationBarBaseButtonStyles = cx(css(resetStyles.button, {
    "&[aria-expanded=true]": {
        color: themeTokens.color.navigation.link.icon.active,
        background: themeTokens.color.navigation.link.background.active,
    },
}), navigationBarItemStyles);
export const navigationBarTextButtonStyles = cx(navigationBarBaseButtonStyles, css({
    paddingInlineStart: space[12],
    paddingInlineEnd: space[12],
    gap: space[8],
}));
export const navigationBarIconButtonStyles = navigationBarBaseButtonStyles;
