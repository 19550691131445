import * as globals from "./globals";
import type { OctopusTextTheme } from "./OctopusTextTheme";
export const textV2Theme: OctopusTextTheme = {
    "textHeadingXSmall": "700 0.75rem/1.25rem system-ui, -apple-system, segoe ui, sans-serif",
    "textHeadingSmall": "700 1rem/1.5rem system-ui, -apple-system, segoe ui, sans-serif",
    "textHeadingMedium": "700 1.25rem/1.75rem system-ui, -apple-system, segoe ui, sans-serif",
    "textHeadingLarge": "700 1.5rem/2rem system-ui, -apple-system, segoe ui, sans-serif",
    "textHeadingXLarge": "700 2rem/2.75rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularDefaultXSmall": "400 0.75rem/1.25rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularDefaultSmall": "400 0.813rem/1.25rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularDefaultMedium": "400 0.875rem/1.25rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularDefaultLarge": "400 1rem/1.5rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularBoldXSmall": "700 0.75rem/1.25rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularBoldSmall": "700 0.813rem/1.25rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularBoldMedium": "700 0.875rem/1.25rem system-ui, -apple-system, segoe ui, sans-serif",
    "textRegularBoldLarge": "700 1rem/1.5rem system-ui, -apple-system, segoe ui, sans-serif",
    "fontFamilyDefault": "system-ui, -apple-system, segoe ui, sans-serif",
    "fontFamilyCode": "Roboto Mono"
};
