import * as globals from "./globals";
import type { OctopusTextTheme } from "./OctopusTextTheme";
export const textTheme: OctopusTextTheme = {
    "textHeadingXSmall": "500 0.75rem/1.25rem Roboto",
    "textHeadingSmall": "500 1rem/1.5rem Roboto",
    "textHeadingMedium": "500 1.25rem/1.75rem Roboto",
    "textHeadingLarge": "500 1.5rem/2rem Roboto",
    "textHeadingXLarge": "500 2rem/2.75rem Roboto",
    "textRegularDefaultXSmall": "400 0.75rem/1.25rem Roboto",
    "textRegularDefaultSmall": "400 0.813rem/1.25rem Roboto",
    "textRegularDefaultMedium": "400 0.875rem/1.5rem Roboto",
    "textRegularDefaultLarge": "400 1rem/1.5rem Roboto",
    "textRegularBoldXSmall": "500 0.75rem/1.25rem Roboto",
    "textRegularBoldSmall": "500 0.813rem/1.25rem Roboto",
    "textRegularBoldMedium": "500 0.875rem/1.5rem Roboto",
    "textRegularBoldLarge": "500 1rem/1.5rem Roboto",
    "fontFamilyDefault": "Roboto",
    "fontFamilyCode": "Roboto Mono"
};
